@use '../../_theme' as *
@use 'sass:color'
    
.BodyDragging
    .BodyDraggingDiv
        display: flex !important

.BodyDraggingDiv
    display: none
    position: fixed
    left: 0
    right: 0
    bottom: 0
    top: 0
    width: 100vw
    height: 100vh
    flex-flow: column nowrap
    align-items: center
    justify-content: center
    background-color: #3b62d6 !important
    z-index: 100
    pointer-events: none

    *
        color: white
        text-align: center

.splash
    background-image: url(https://unsplash.com/photos/HyU6sLdg2xk/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8Mjd8fGhvdCUyMGFpciUyMGJhbGxvb258ZW58MHx8fHwxNjcxMDM0ODQ5&force=true&w=2400),
    background-size: cover
    background-position: 50% 65%
    background-repeat: no-repeat

.box
    padding-left: 0.2em
    padding-right: 0.2em

.list
    max-height: 50vh
    overflow-y: auto
    padding-right: 0.2em

    &.roundButtons
        :global(.button)
            width: 2.5em
            padding-left: 0
            padding-right: 0
            border-width: 2px

            :global(.icon)
                svg
                    font-size: 1.05rem

    .item
        display: flex
        align-items: center
        justify-content: space-between
        background: #f6f7f9
        padding: 1em

        &:not(.isAddButton)
            border-top: 1px solid #eaeaef
            border-bottom: 1px solid #eaeaef

        :global(.buttons)
            opacity: 0

        &:hover
            background: #edeff4

            :global(.buttons)
                opacity: 1

        &.ignoreHover
            :global(.buttons)
                opacity: 1 !important

        &.isAddButton
            justify-content: flex-start
            gap: 1em
            cursor: pointer

            &:hover
                :global(.icon)
                    border-color: color.scale($primary, $lightness: -30%)
                    color: color.scale($primary, $lightness: -30%)


            :global(.icon)
                width:  2.5rem
                height: 2.5rem
                border-radius: 50%
                border: 2px solid $primary
                color: $primary

@media screen and ( max-width: 769px)
    .splash

        :global(.container)
            padding-bottom: 15vh

        :global(.hero-body)
            padding-left:  0.25em !important
            padding-right: 0.25em !important
    