@use 'sass:color'
@import './theme'

// Nunito, Cabin Font & Noto Sans
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,600;0,700;1,800&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

// Variable Changes
$family-sans-serif: 'Nunito', 'Noto Sans', 'Cabin', Arial, Helvetica, sans-serif //"Cabin", "Noto Sans", Helvetica, Arial, sans-serif

@import 'bulma-navarrotech/brands'
@import 'bulma-navarrotech/bulma'
@import 'bulma-divider'

.is-orange
    fill: $orange
    color: $orange
.is-blue
    fill: $blue
    color: $blue
.is-green
    fill: $green
    color: $green
.is-red
    fill: $red
    color: $red
.is-purple
    fill: $purple
    color: $purple
.is-yellow
    fill: color.scale($yellow, $lightness: -10%)
    color: color.scale($yellow, $lightness: -10%)

.navbar .navbar-brand
    .title
        font-family: 'Noto Sans', 'Cabin', 'Nunito', Arial, Helvetica, sans-serif !important
        letter-spacing: -1px

    .navbar-item
        width: 70px
        height: 70px

// .button.is-primary
//     border: 0
//     @include primaryGradient(80deg)

//     &:hover
//         @include primaryGradientLight(80deg)

input
    font-family: 'Noto Sans', 'Cabin', Arial, Helvetica, sans-serif !important

.button.is-google
    $googleButtonHeight: 2.75em
    height: $googleButtonHeight !important
    color: #ffffff
    background: $google
    position: relative
    font-weight: bold
    font-family: 'Noto Sans', 'Cabin', Arial, Helvetica, sans-serif !important
    padding-left: calc($googleButtonHeight + 4px)

    .icon
        position: absolute
        width: calc($googleButtonHeight - 6px)
        height: calc($googleButtonHeight - 6px)
        background: white
        border-radius: 2px
        left: 2px
        top: 2px
        margin-left: 0 !important
        margin-right: 0 !important

.nametag
    figure.is-64x64
        min-width: 64px
        max-width: 64px
        max-height: 64px
        max-height: 64px

// Offset the navbar from the content!
.navbarSpacer
    margin-top: 80px

.splash .hero-body
    z-index: 2

.about-page
    background-size: cover

    .image.is-big
        border-radius: 8px
        img
            border-radius: 8px

.instagram-tiles
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: 100%
    height: fit-content
    gap: 10px

    .instagramImage
        width: calc(30% - 5px)

        .image
            border-radius: 8px
            overflow: hidden

        p
            text-align: center

// Homepage

@keyframes artSpin
    from
        transform: rotate(0deg)

    to
        transform: rotate(360deg)

.BackgroundVideo
    width: 100vw
    height: 100vh
    object-fit: cover
    position: absolute
    left: 0
    right: 0
    top: 0
    // z-index: -1

.bigWelcome
    font-weight: 800 !important

.contactPage

    .input,
    .select,
    select,
    .textarea
        border: 0px
        box-shadow: none !important
        outline: none !important

        &:focus,
        &:active,
        &:hover
            background: $light

    .column.is-variable
        max-width: 342px

    .control-accent
        content: ' '
        display: block
        width: 100%
        height: 4px

        &.is-red
            background: linear-gradient(45deg, $red 40%, $orange 100%)
        &.is-orange
            background: linear-gradient(45deg, $orange 40%, $yellow 100%)
        &.is-yellow
            background: linear-gradient(45deg, $yellow 40%, $green 100%)
        &.is-green
            background: linear-gradient(45deg, $green 40%, $blue 100%)
        &.is-blue
            background: linear-gradient(45deg, $blue 40%, $purple 100%)
        &.is-purple
            background: linear-gradient(45deg, $purple 40%, $red 100%)

    .field-icon
        justify-content: flex-start
        width: 4rem
        height: 4rem

        svg
            width: 2.5rem
            height: 2.5rem

.homePage
    &.hero.is-grey
        position: relative
        background: #f7fafc
        background: linear-gradient(33deg, #d7dee1 0%, #ffffff 100%)

    .image.is-phone
        position: absolute
        top: -9em
        bottom: -9em
        right: -250px

    .art
        position: absolute
        height: 2500px
        width: 2500px
        top: -300px
        animation: artSpin 12s linear infinite

footer
    .links a
        color: white
        font-weight: bold
        &:hover
            color: $primary !important

    .HoverGlow:hover
        color: $primary !important

.servicesLine
    width: 100vw
    height: 4px
    position: absolute
    top: 39px
    left: 0
    right: 0
    background: $primary
    background: rgba(0,0,0,0) linear-gradient(270deg, $green 0%, $blue 31%, $purple 56%, $red 100%) 0% 0% no-repeat padding-box

.servicesRing
    margin: 1em 0
    border: 3px solid #f7f7f7
    border-radius: 50%
    height: 50px
    width: 50px
    background: white
    display: flex
    align-items: center
    justify-content: center

    svg
        width: 30px

.welcomeShadow
    box-shadow: 0 0 60px 60px rgba(0,0,0,0.6), inset 0 0 200px rgba(0,0,0,0.6)
    background: rgba(0,0,0,0.4)
    position: absolute
    top: -20px
    bottom: 0
    left: 0
    right: 0
    z-index: -1
    width: 90%
    margin: auto

.is-size-0
    font-size: 4rem

.welcomeBox
    max-width: 800px !important

.primary-text-gradient
    @include primary-text-gradient

.typeWriter

    > div
        display: inline-block

    span
        @include primary-text-gradient

// About page

// Techstack
.imageWrap
    display: flex
    flex-wrap: wrap
    flex-direction: row

    .image
        width: 152px
        height: 152px
