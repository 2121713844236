@use 'sass:color'

// Navarrotech Brand
$orange:     #FF7700
$yellow:     #dfd902
$red:        #FF3554
$redLight:   #E13834
$green:      #20dc86
$blue:       #0092f4
$purple:     #da54d8
$gray:       #3E3B3B
$light:      #f0f2f5

// Brands
$facebook:   #0054f7
$linkedin:   #0072b1
$youtube:    #FF0000
$instagramA: #7d3aad
$instagramB: #df1338
$instagramC: #fadc02
$tiktokA:    #ff0050
$tiktokB:    #00f2ea
$twitter:    #00b9ff
$fishdawg:   #7F9AB7
$paypal:     #00457C
$venmo:      #3D95CE
$zoom:       #0E72EC

// Alter Bulma variables
$primary: $red
$text: $gray

// Bulma Overrides

// Navbar
$navbar-background-color: black
$navbar-item-color: #ffffff
$navbar-item-hover-color: $primary
$navbar-item-active-color: $primary
$navbar-item-img-max-height: unset
$navbar-item-hover-background-color: transparent

// Footer
$footer-background-color: black
$footer-color: white

@mixin primaryGradient($angle: 45deg)
    background: linear-gradient($angle, $red 40%, $yellow 100%)

@mixin primaryGradientLight($angle: 45deg)
    background: linear-gradient($angle, color.scale($red, $lightness: 10%) 40%, color.scale($yellow, $lightness: 10%) 100%)

@mixin shadow($strength)
    box-shadow: 0 0 10px 4px rgba(0,0,0,$strength/100)

@mixin instagram-gradient($angle: 210deg)
    background: $instagramA
    background: linear-gradient($angle, $instagramC 0%, $instagramB 33%, $instagramA 100%)

@mixin primary-text-gradient($angle: -90deg)
    // color: $primary
    background: $primary
    background: linear-gradient(45deg, $red 0%, $yellow 100%)
    -webkit-background-clip: text
    background-clip: text
    -webkit-text-fill-color: transparent
    text-fill-color: transparent
    background-size: 100%